import BackToTopButton from 'components/shared/BackToTopButton';
import CSVUploadDialog from 'components/shared/CSVUploadDialog';
import ErrorNotice from 'components/shared/ErrorNotice';
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner';
import SmallBusinessRequirementContent from 'components/shared/SmallBusinessRequirementContent';
import StyledTable from 'components/shared/Table';
import SmallBusinessClassificationDialog from 'components/SmallBusinessClassificationAdminDialog/SmallBusinessClassificationAdminDialog';
import { CLASSIFICATION_VIEWS } from 'constants/adminDialogViews';
import { SmallBusinessClassificationName } from 'constants/classificationNames';
import { SmallBusinessAdminRequirementOption } from 'constants/smallBusinessRequirementOptions';
import useRoles from 'hooks/useRoles';
import { MUIDataTableColumn, MUIDataTableOptions, MUISortOptions } from 'mui-datatables';
import { FC, MouseEvent, useState } from 'react';
import { GetSmallBusinessClassificationsQuery, useGetSmallBusinessClassificationsQuery } from 'types/generated/graphql';
import { closeDialog, getColumnFormattedAdminData, openAdminDialog } from 'utils/adminTables';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Grid, IconButton, SxProps, Theme, Tooltip } from '@mui/material';

const actionsContainer: SxProps<Theme> = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('md')]: { justifyContent: 'flex-start' },
});

const hiddenIconStyles: SxProps<Theme> = {
  height: '40px',
};

const SmallBusinessClassificationAdminTable: FC = () => {
  const { isApplicationAdmin } = useRoles();
  const [smallBusinessClassificationDialogIsOpen, setSmallBusinessClassificationDialogIsOpen] = useState(false);
  const [smallBusinessClassificationData, setSmallBusinessClassificationData] = useState([]);
  const [smallBusinessClassificationDialogView, setSmallBusinessClassificationDialogView] = useState<any>();

  const [csvUploadDialogIsOpen, setCsvUploadDialogIsOpen] = useState(false);
  const [adminTableType, setAdminTableType] = useState('');

  const LOCAL_STORAGE_CLASSIFICATION_ADMIN_SORT_KEY = 'classificationAdminSort';
  const savedClassificationAdminTableSort = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_CLASSIFICATION_ADMIN_SORT_KEY) as string,
  );
  const [classificationAdminTableSort, setClassificationAdminTableSort] = useState<MUISortOptions>(
    !savedClassificationAdminTableSort
      ? {
          name: 'name',
          direction: 'asc',
        }
      : savedClassificationAdminTableSort,
  );

  type SmallBusinessClassificationsSelection = GetSmallBusinessClassificationsQuery['smallBusinessClassificationList'];

  const { EDIT_CLASSIFICATION, ADD_CLASSIFICATION, DELETE_CLASSIFICATION } = CLASSIFICATION_VIEWS;

  const { data, loading, error } = useGetSmallBusinessClassificationsQuery();

  const smallBusinessClassificationList: SmallBusinessClassificationsSelection =
    data?.smallBusinessClassificationList ?? [];

  const handleCsvUploadDialog = (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    setCsvUploadDialogIsOpen(true);
  };

  const getUploadCsvButton = (id?: string) => {
    return (
      <Tooltip title={'Classification Mass Upload'}>
        <IconButton
          id={id}
          onClick={(event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
            setAdminTableType(event.currentTarget.id);
            handleCsvUploadDialog(event);
          }}
        >
          <FileUploadIcon />
        </IconButton>
      </Tooltip>
    );
  };

  const columns: MUIDataTableColumn[] = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'name',
      label: 'Classification Name',
      options: {
        filter: true,
        filterOptions: {
          fullWidth: true,
        },
      },
    },
    {
      name: 'abbreviation',
      label: 'Abbreviation',
      options: {
        filter: true,
        filterType: 'multiselect',
        sort: false,
      },
    },
    {
      name: 'isActive',
      label: '',
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'isFederal',
      label: 'SB Requirement',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => (
          <SmallBusinessRequirementContent
            value={
              smallBusinessClassificationList[dataIndex]?.isFederal === true
                ? SmallBusinessAdminRequirementOption.FEDERAL
                : smallBusinessClassificationList[dataIndex]?.isFederal === false
                ? SmallBusinessAdminRequirementOption.NON_FEDERAL
                : SmallBusinessAdminRequirementOption.ANY_ALL
            }
          />
        ),
        filterOptions: {
          names: Object.values(SmallBusinessAdminRequirementOption),
          fullWidth: true,
          logic: (isFederal, filters) => {
            const federal = Object.is(isFederal, true);
            const nonFederal = Object.is(isFederal, false);
            if (filters.length) {
              const sbRequirement = federal
                ? SmallBusinessAdminRequirementOption.FEDERAL
                : nonFederal
                ? SmallBusinessAdminRequirementOption.NON_FEDERAL
                : SmallBusinessAdminRequirementOption.ANY_ALL;
              return !filters.includes(sbRequirement);
            }
            /* istanbul ignore next */
            return false; // this line can never be tested as filters will always exist
          },
        },
      },
    },
    {
      name: 'sortOrder',
      label: '',
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'edit',
      label: ' ',
      options: {
        filter: false,
        searchable: false,
        sort: false,
        display: isApplicationAdmin,
        // eslint-disable-next-line react/display-name
        customBodyRender: (_, tableMeta) => {
          const rowData = tableMeta.rowData;
          const classificationNameRowIndex = 1;
          const classificationName = rowData[classificationNameRowIndex];
          const smallBusinessTotalClassification = SmallBusinessClassificationName.SBT;
          const largeBusinessClassification = SmallBusinessClassificationName.LB;

          return classificationName === smallBusinessTotalClassification ||
            classificationName === largeBusinessClassification ? (
            <Grid item sx={hiddenIconStyles}></Grid>
          ) : (
            <>
              <Grid container sx={actionsContainer}>
                <Grid item>
                  <span>
                    <Tooltip title={'Edit Classification'}>
                      <IconButton
                        onClick={() =>
                          openAdminDialog(
                            rowData,
                            setSmallBusinessClassificationData,
                            setSmallBusinessClassificationDialogIsOpen,
                            setSmallBusinessClassificationDialogView,
                            EDIT_CLASSIFICATION,
                          )
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </span>
                </Grid>
              </Grid>
            </>
          );
        },
      },
    },
    {
      name: 'delete',
      label: ' ',
      options: {
        filter: false,
        searchable: false,
        sort: false,
        display: isApplicationAdmin,
        // eslint-disable-next-line react/display-name
        customBodyRender: (_, tableMeta) => {
          const rowData = tableMeta.rowData;
          const classificationNameRowIndex = 1;
          const classificationName = rowData[classificationNameRowIndex];
          const smallBusinessTotalClassification = SmallBusinessClassificationName.SBT;
          const largeBusinessClassification = SmallBusinessClassificationName.LB;

          return classificationName === smallBusinessTotalClassification ||
            classificationName === largeBusinessClassification ? (
            <Grid item sx={hiddenIconStyles}></Grid>
          ) : (
            <>
              <Grid container sx={actionsContainer}>
                <Grid item>
                  <span>
                    <Tooltip title={'Delete Classification'}>
                      <IconButton
                        onClick={() =>
                          openAdminDialog(
                            rowData,
                            setSmallBusinessClassificationData,
                            setSmallBusinessClassificationDialogIsOpen,
                            setSmallBusinessClassificationDialogView,
                            DELETE_CLASSIFICATION,
                          )
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </span>
                </Grid>
              </Grid>
            </>
          );
        },
      },
    },
  ];

  const handleSortChange = (name: string, direction: 'asc' | 'desc') => {
    setClassificationAdminTableSort({ name: name, direction: direction });
    localStorage.setItem(LOCAL_STORAGE_CLASSIFICATION_ADMIN_SORT_KEY, JSON.stringify({ name, direction }));
  };

  const options: MUIDataTableOptions = {
    count: smallBusinessClassificationList?.length,
    rowsPerPageOptions: [],
    download: false,
    sortOrder: { name: classificationAdminTableSort?.name, direction: classificationAdminTableSort?.direction },
    onColumnSortChange(changedColumns, direction) {
      handleSortChange(changedColumns, direction);
    },
    onTableInit(_, tableState) {
      const savedClassificationAdminTableSort = classificationAdminTableSort;
      tableState.sortOrder = savedClassificationAdminTableSort;
    },
    customToolbar: (data) => {
      const rowDataIndex = data.displayData.length;
      return (
        <>
          {isApplicationAdmin && (
            <>
              <Tooltip title={'Add Classification'}>
                <IconButton
                  onClick={() =>
                    openAdminDialog(
                      [rowDataIndex],
                      setSmallBusinessClassificationData,
                      setSmallBusinessClassificationDialogIsOpen,
                      setSmallBusinessClassificationDialogView,
                      ADD_CLASSIFICATION,
                    )
                  }
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={'Agency Mass Upload'}>
                <>{getUploadCsvButton('classificationAdminTable')}</>
              </Tooltip>
            </>
          )}
        </>
      );
    },
  };

  if (error) {
    console.error(error);
    return <ErrorNotice />;
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <StyledTable
        title="Classification List"
        data={getColumnFormattedAdminData(smallBusinessClassificationList) ?? []}
        columns={columns}
        options={options}
      />
      <BackToTopButton />
      {smallBusinessClassificationDialogIsOpen && (
        <SmallBusinessClassificationDialog
          isOpen={smallBusinessClassificationDialogIsOpen}
          setIsOpen={setSmallBusinessClassificationDialogIsOpen}
          handleClose={() =>
            closeDialog(setSmallBusinessClassificationDialogIsOpen, setSmallBusinessClassificationDialogView)
          }
          smallBusinessClassificationData={smallBusinessClassificationData}
          smallBusinessClassificationDialogView={smallBusinessClassificationDialogView}
          setSmallBusinessClassificationDialogView={setSmallBusinessClassificationDialogView}
        />
      )}
      <CSVUploadDialog
        isOpen={csvUploadDialogIsOpen}
        setIsOpen={setCsvUploadDialogIsOpen}
        adminTableType={adminTableType}
        smallBusinessAgenciesList={[]}
        smallBusinessClassificationsList={smallBusinessClassificationList}
        smallBusinessClientList={[]}
      />
    </>
  );
};
export default SmallBusinessClassificationAdminTable;
