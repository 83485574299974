import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary';
import StyledDialog from 'components/shared/Dialog';
import { SmallBusinessClassificationName } from 'constants/classificationNames';
import { UPDATE_PROJECT } from 'graphql/projects';
import useToast from 'hooks/useToast';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import {
  LowerTierParticipationClassificationSummariesFromQuery,
  PickedSmallBusinessReportClassificationSummary,
} from 'types';
import {
  GetSingleProjectQueryResult,
  SmallBusinessReportClassificationSummary,
  useDeleteLowerTierParticipationMutation,
} from 'types/generated/graphql';

import { useApolloClient, useMutation } from '@apollo/client';
import { Grid } from '@mui/material';

type DeleteLowerTierParticipationProps = {
  isOpen: boolean;
  setIsOpen: (x: boolean) => void;
  lowerTierParticipation?: LowerTierParticipationClassificationSummariesFromQuery[number];
  smallBusinessReportSettings?: Exclude<
    GetSingleProjectQueryResult['data'],
    undefined
  >['project']['smallBusinessReportSettings'];
  smallBusinessReportClassificationSummaries?: SmallBusinessReportClassificationSummary[];
  contractId?: string;
};

type useParamsProps = {
  projectId?: string;
};

const DeleteLowerTierParticipationDialog: FC<DeleteLowerTierParticipationProps> = ({
  isOpen,
  setIsOpen,
  lowerTierParticipation,
  smallBusinessReportSettings,
  smallBusinessReportClassificationSummaries,
  contractId,
}) => {
  const { displayToast } = useToast();
  const apolloClient = useApolloClient();
  const { projectId } = useParams<useParamsProps>();

  const [deleteLowerTierParticipationMutation] = useDeleteLowerTierParticipationMutation();

  const [updateProject] = useMutation(UPDATE_PROJECT, {
    awaitRefetchQueries: true,
  });

  const handleClose = () => {
    setIsOpen(false);
  };

  const onSubmitSuccess = () => {
    deleteLowerTierParticipationMutation({ variables: { id: lowerTierParticipation?.lowerTierParticipationId ?? '' } });

    const lowerTierParticipationId = lowerTierParticipation?.lowerTierParticipationId;

    const existingClassifications: PickedSmallBusinessReportClassificationSummary[] = [];
    smallBusinessReportClassificationSummaries
      ?.filter((classification) => classification.name !== SmallBusinessClassificationName.SBT)
      .forEach((classification) => {
        existingClassifications.push({
          smallBusinessClassificationId: classification.smallBusinessClassificationId,
          goalPercent: parseFloat(`${classification.goalPercent}`),
        });
      });

    let classifications: PickedSmallBusinessReportClassificationSummary[] = [];
    if (existingClassifications) {
      classifications = existingClassifications;
    }

    const classificationsToRemove: string[] = [];

    smallBusinessReportClassificationSummaries?.forEach((summary) =>
      (!summary?.contractIds?.length ||
        (summary?.contractIds.length === 1 && summary?.contractIds?.includes(contractId as string))) &&
      summary.lowerTierParticipationIds?.length === 1 &&
      summary.lowerTierParticipationIds?.includes(lowerTierParticipationId as string) &&
      summary.goalPercent === 0 &&
      summary.name !== SmallBusinessClassificationName.LB &&
      summary.name !== SmallBusinessClassificationName.SBT
        ? classificationsToRemove.push(summary.smallBusinessClassificationId)
        : null,
    );

    updateProject({
      variables: {
        input: {
          id: projectId,
          smallBusinessReportSettings: {
            id: smallBusinessReportSettings?.id,
            classifications: classifications?.filter((classification) => {
              return !classificationsToRemove.includes(String(classification.smallBusinessClassificationId));
            }),
          },
        },
      },
    })
      .then(() => {
        apolloClient.reFetchObservableQueries();
        handleClose();
        displayToast('The lower tier participation contract was deleted successfully', 'success');
      })
      .catch((error) => {
        console.error(error);
        displayToast(
          'Error: Something went wrong while trying to delete the lower tier participation contract. Please try again. If the problem persists, please contact support.',
          'error',
        );
      });
  };

  return (
    <StyledDialog
      title={'Delete Lower Tier Participation'}
      content={`You are deleting the contract for: ${lowerTierParticipation?.lowerTierCompanyName}. This action cannot be undone.`}
      actions={
        <Grid container>
          <Grid item xs={12}>
            <Grid container justifyContent={'space-between'}>
              <Grid item>
                <StyledButtonSecondary disabled={false} label={'cancel'} onClick={handleClose} />
              </Grid>
              <Grid item>
                <StyledButtonPrimary label={'Delete'} type="submit" onClick={onSubmitSuccess} disabled={false} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      isOpen={isOpen}
      handleClose={handleClose}
      fullWidth={true}
      disableEscapeKeyDown={true}
    />
  );
};

export default DeleteLowerTierParticipationDialog;
