import CompanySelect from 'components/company/CompanySelect';
import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary/ButtonSecondary';
import StyledDialog from 'components/shared/Dialog';
import StyledNotice, { NoticeTypeOption } from 'components/shared/Notice';
import StyledDatePicker from 'components/shared/StyledDatePicker/StyledDatePicker';
import { FederalSmallBusinessClassificationName, SmallBusinessClassificationName } from 'constants/classificationNames';
import { SmallBusinessRequirementOption } from 'constants/smallBusinessRequirementOptions';
import { GET_SINGLE_PROJECT, UPDATE_PROJECT } from 'graphql/projects';
import { GET_SMALL_BUSINESS_REPORT } from 'graphql/smallBusinessReport';
import useCompany from 'hooks/useCompany';
import useToast from 'hooks/useToast';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { useParams } from 'react-router-dom';
import { SmallBusinessFederalReportFormOptionSelection } from 'recoil/smallBusinessFederalReportFormOption/atom';
import { SmallBusinessClassificationSelection, SmallBusinessReportSettingsFromQuery } from 'types';
import {
  GetSingleProjectQuery,
  SmallBusinessAgency,
  SmallBusinessClassification,
  SmallBusinessReportClassificationSummary,
  useUpsertContractMutation,
} from 'types/generated/graphql';
import { convertUTCDateToLocalDate } from 'utils/general';

import { useApolloClient, useMutation } from '@apollo/client';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';

const containerStyles: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(2),
});

const noticeStyles: SxProps<Theme> = (theme: Theme) => ({
  paddingTop: theme.spacing(1),
});

const formStyles: SxProps<Theme> = {
  width: '100%',
};

const infoLabel: SxProps<Theme> = {
  fontWeight: 'bolder',
  color: 'black',
  fontSize: '.90rem',
};

const noticeLabel: SxProps<Theme> = (theme: Theme) => ({
  fontWeight: 'bolder',
  paddingBottom: theme.spacing(0.5),
});

const checkboxLabel: SxProps<Theme> = {
  marginLeft: 0,
};

const gridLabelStyles: SxProps<Theme> = {
  marginTop: 2,
};

const errorMessageStyle: SxProps<Theme> = {
  color: '#cd0000',
  marginLeft: '14px',
  marginTop: '4px',
  fontSize: '.75rem',
};

type EditContractDialogProps = {
  isOpen: boolean;
  setIsOpen: (x: boolean) => void;
  contractId?: string;
  companyId?: string;
  phaseCode?: string;
  valueTotal?: number;
  effectiveDate?: DateTime;
  isSelfWork: boolean;
  participationPercentage: number;
  smallBusinessReportSettings?: SmallBusinessReportSettingsFromQuery;
  smallBusinessReportClassificationSummaries?: SmallBusinessReportClassificationSummary[];
  project?: GetSingleProjectQuery['project'];
  allSmallBusinessClassifications: SmallBusinessClassificationSelection;
  selectedReportOption?: SmallBusinessFederalReportFormOptionSelection;
};

type useParamsProps = {
  projectId?: string;
};

type PickedSmallBusinessReportClassificationSummary = Pick<
  SmallBusinessReportClassificationSummary,
  'smallBusinessClassificationId' | 'goalPercent'
>;

const EditContractDialog: React.FC<EditContractDialogProps & Record<string, any>> = ({
  isOpen,
  setIsOpen,
  contractId,
  companyId,
  phaseCode,
  valueTotal,
  effectiveDate,
  isSelfWork,
  participationPercentage,
  smallBusinessReportSettings,
  smallBusinessReportClassificationSummaries,
  project,
  allSmallBusinessClassifications,
  selectedReportOption,
}) => {
  const { displayToast } = useToast();
  const apolloClient = useApolloClient();
  const { projectId } = useParams<useParamsProps>();

  const projectSmallBusinessRequirement = project?.smallBusinessRequirement;
  const isNonFederalProject = projectSmallBusinessRequirement === SmallBusinessRequirementOption.NON_FEDERAL;
  const isHpSelfReporting = projectSmallBusinessRequirement === SmallBusinessRequirementOption.HP_SELF_REPORTING;

  const [upsertContract, { loading: upsertContractIsLoading }] = useUpsertContractMutation({
    refetchQueries: [
      {
        query: GET_SINGLE_PROJECT,
        variables: { id: projectId },
      },
      {
        query: GET_SMALL_BUSINESS_REPORT,
        variables: {
          smallBusinessReportSettingsId: smallBusinessReportSettings?.id,
          form: selectedReportOption === '' ? undefined : selectedReportOption,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const [updateProject, { loading: updateProjectIsLoading }] = useMutation(UPDATE_PROJECT, {
    awaitRefetchQueries: true,
  });

  const smallBusinessTotalClassification = allSmallBusinessClassifications.find(
    (classification) => classification.name === SmallBusinessClassificationName.SBT,
  ) as SmallBusinessClassification;

  const [shouldShowHubzoneMessage, setShouldShowHubzoneMessage] = useState(false);

  interface ContractFormValues {
    companyId: string | undefined;
    effectiveDate: DateTime | undefined;
    isSelfWork: boolean;
    participationPercentage: number;
  }

  const defaultValues: ContractFormValues = {
    companyId: companyId,
    effectiveDate: effectiveDate,
    isSelfWork: isSelfWork,
    participationPercentage: participationPercentage,
  };

  const { handleSubmit, control, reset, setValue, watch, formState } = useForm({
    defaultValues,
  });

  useEffect(() => {
    // using this if statement because even though typescript thinks its a MaterialUiPickersDate its for some reason a string
    if (typeof effectiveDate == 'string') {
      setValue('effectiveDate', DateTime.fromISO(effectiveDate));
    } else {
      setValue('effectiveDate', effectiveDate);
    }
    setValue('companyId', companyId);
    setValue('isSelfWork', isSelfWork);
    setValue('participationPercentage', participationPercentage);
  }, [setValue, effectiveDate, companyId, isSelfWork, participationPercentage]);

  const selectedEffectiveDate = watch('effectiveDate');
  const selectedCompanyId = watch('companyId');
  const selectedSelfWork = watch('isSelfWork');
  const selectedParticipationPercentage = watch('participationPercentage');

  const { company } = useCompany(selectedCompanyId);

  const companyIsNotLargeBusiness = company?.smallBusinessClassifications?.some(
    (classification) => classification.name !== SmallBusinessClassificationName.LB,
  );

  const companyAgencies = company?.files.flatMap((companyFile) =>
    companyFile.smallBusinessClassificationCoverages.map(
      (classificationCoverage) => classificationCoverage.smallBusinessAgency,
    ),
  );
  const projectAgencies = project?.smallBusinessClient?.smallBusinessClientAgencies.map(
    (agency) => agency.smallBusinessAgency,
  );

  const isCompanyAgencyInProjectAgenciesArr = (companyAgency: any) => {
    return projectAgencies?.some((projectAgency) => projectAgency.id === companyAgency?.id);
  };
  const companyAgenciesAcceptedByProject = companyAgencies?.filter((companyAgency) =>
    isCompanyAgencyInProjectAgenciesArr(companyAgency),
  ) as SmallBusinessAgency[];

  const companyAgenciesAcceptedByProjectWithoutDups = companyAgenciesAcceptedByProject?.reduce(
    (accumulator: any[], companyAgency: SmallBusinessAgency) => {
      if (!accumulator.some((item) => item.id === companyAgency?.id)) {
        accumulator.push(companyAgency);
      }
      return accumulator;
    },
    [],
  );

  const companyAgenciesHaveSpecialNoticeOrDocumentation = companyAgencies?.some(
    (agency) => agency?.specialNotice || agency?.url,
  );

  const handleClose = () => {
    // using this if statement because even though typescript thinks its a MaterialUiPickersDate its for some reason a string
    if (typeof effectiveDate == 'string') {
      reset({
        companyId: companyId,
        effectiveDate: DateTime.fromISO(effectiveDate),
        isSelfWork: isSelfWork,
        participationPercentage: participationPercentage,
      });
    } else {
      reset({
        companyId: companyId,
        effectiveDate: effectiveDate,
        isSelfWork: isSelfWork ?? false,
        participationPercentage: participationPercentage,
      });
    }
    setIsOpen(false);
    setShouldShowHubzoneMessage(false);
  };

  const percentageHasError = () => {
    return (
      selectedParticipationPercentage &&
      !(selectedParticipationPercentage >= 0 && selectedParticipationPercentage <= 100)
    );
  };

  const isFormValid = () => {
    return (
      (!selectedCompanyId && !selectedEffectiveDate && !selectedSelfWork) ||
      ((selectedCompanyId !== undefined && selectedEffectiveDate !== null && selectedEffectiveDate?.isValid) as boolean)
    );
  };

  const { isDirty } = formState;

  const handleIsAllowed = (values: NumberFormatValues) => {
    const { formattedValue, floatValue } = values;
    if (floatValue === undefined) {
      setValue('participationPercentage', 0);
    }
    return (floatValue !== undefined && floatValue >= 0) || formattedValue === '';
  };

  const onSubmit: SubmitHandler<ContractFormValues> = (data) => {
    if (!contractId) {
      displayToast(
        'Error: Something went wrong while trying to update the contract information. Please contact support.',
        'error',
      );
      return;
    }

    upsertContract({
      variables: {
        input: {
          id: contractId,
          effectiveDate: data.effectiveDate?.toISODate() ?? null,
          companyId: data.companyId ?? null,
          isSelfWork: data.isSelfWork,
          participationPercentage:
            !data.companyId && (!isNonFederalProject || !isHpSelfReporting) ? 100 : data.participationPercentage,
        },
      },
    })
      .then((result) => {
        if (isNonFederalProject || isHpSelfReporting) {
          const companyClassifications = result.data?.upsertContract.company?.smallBusinessClassifications?.filter(
            (smallBusinessClassification) => smallBusinessClassification.isFederal !== true,
          );
          const companyClassificationIds = companyClassifications?.map((classification) => classification.id);

          const clientSupportedClassifications =
            project?.smallBusinessClient?.smallBusinessClientAgencies?.flatMap((agency) =>
              agency?.smallBusinessClientClassifications?.flatMap(
                (classification) => classification.smallBusinessClassification,
              ),
            ) ?? [];

          const clientSupportedClassificationsWithoutDuplicates = clientSupportedClassifications.filter(
            (smallBusinessClientClassification, index) =>
              clientSupportedClassifications.findIndex(
                (classification) => classification.id === smallBusinessClientClassification.id,
              ) === index,
          );

          const classificationsToDisplay = clientSupportedClassificationsWithoutDuplicates.filter((classification) =>
            companyClassificationIds?.includes(classification.id as any),
          );

          classificationsToDisplay.some((classification) => {
            if (classification.name !== SmallBusinessClassificationName.LB) {
              classificationsToDisplay.push(smallBusinessTotalClassification);
            }
          });

          const classificationsToAdd = classificationsToDisplay
            ?.filter((smallBusinessClassification) => smallBusinessClassification.isFederal !== true)
            .map((classification) => {
              return {
                smallBusinessClassificationId: classification.id,
                goalPercent: 0,
              };
            }) as PickedSmallBusinessReportClassificationSummary[];

          const existingClassifications: PickedSmallBusinessReportClassificationSummary[] = [];
          smallBusinessReportClassificationSummaries?.forEach((classification) => {
            existingClassifications.push({
              smallBusinessClassificationId: classification.smallBusinessClassificationId,
              goalPercent: parseFloat(`${classification.goalPercent}`),
            });
          });

          let classifications: PickedSmallBusinessReportClassificationSummary[] = [];
          if (existingClassifications) {
            classifications = existingClassifications;
          }
          if (classificationsToAdd) {
            classifications = classificationsToAdd;
          }
          if (classificationsToAdd && existingClassifications) {
            classifications = classificationsToAdd.concat(existingClassifications);
          }

          const classificationsToRemove: string[] = [];

          smallBusinessReportClassificationSummaries?.forEach((summary) => {
            if (
              summary.contractIds?.length === 1 &&
              (!result.data?.upsertContract.company?.id || result.data?.upsertContract.company?.id === companyId) &&
              summary.contractIds?.includes(contractId as string) &&
              !result.data?.upsertContract.company?.id &&
              summary.goalPercent === 0
            ) {
              classificationsToRemove.push(summary.smallBusinessClassificationId);
            }
            return null;
          });

          updateProject({
            variables: {
              input: {
                id: projectId,
                smallBusinessReportSettings: {
                  id: smallBusinessReportSettings?.id,
                  classifications: classifications?.filter((classification) => {
                    return !classificationsToRemove.includes(String(classification.smallBusinessClassificationId));
                  }),
                },
              },
            },
          }).then(() => {
            apolloClient.reFetchObservableQueries();
          });
        }
      })
      .then(() => {
        apolloClient.reFetchObservableQueries();
        displayToast('The contract information was updated successfully', 'success');
        handleClose();
      })
      .catch((error) => {
        console.error(error);
        displayToast(
          'Error: Something went wrong while trying to update the contract information. Please try again. If the problem persists, please contact support.',
          'error',
        );
      });
  };

  return (
    <StyledDialog
      title={'Contract Info'}
      isLoading={upsertContractIsLoading || updateProjectIsLoading}
      content={
        <Grid container sx={containerStyles}>
          <Grid item>
            <Grid container direction="row" spacing={2} justifyContent="space-between">
              <Grid item>
                <Grid container direction="row" spacing={1} justifyContent="space-between">
                  <Grid item>
                    <InputLabel sx={infoLabel}>Phase code:</InputLabel>
                  </Grid>
                  <Grid item>
                    <Typography>{phaseCode}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={1} justifyContent="space-between">
                  <Grid item>
                    <InputLabel sx={infoLabel}>Total:</InputLabel>
                  </Grid>
                  <Grid item>
                    <NumberFormat
                      value={valueTotal}
                      displayType={'text'}
                      prefix={'$'}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      allowLeadingZeros={false}
                      isNumericString={true}
                      thousandSeparator={true}
                      margin="dense"
                      variant="outlined"
                      color="primary"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={formStyles}>
            <form>
              <Grid item>
                <Controller
                  render={({ field }) => (
                    <Grid item sx={gridLabelStyles}>
                      <InputLabel sx={infoLabel}>Company</InputLabel>
                      <CompanySelect<false>
                        selectedCompanyId={field.value}
                        onChange={(value) => {
                          setShouldShowHubzoneMessage(
                            value?.federalSmallBusinessClassifications?.some(
                              (c) => c.name === FederalSmallBusinessClassificationName.HUBZONE,
                            )
                              ? true
                              : false,
                          );
                          field.onChange(value?.id ?? undefined);
                          setValue('companyId', value?.id ?? undefined);
                        }}
                      />
                      <Grid sx={errorMessageStyle}>
                        {(selectedSelfWork || selectedEffectiveDate) && !selectedCompanyId
                          ? 'Company is required when other fields are populated'
                          : null}
                      </Grid>
                    </Grid>
                  )}
                  name="companyId"
                  control={control}
                  rules={{
                    required: (selectedSelfWork || selectedEffectiveDate) && !selectedCompanyId,
                  }}
                />
              </Grid>
              {(isNonFederalProject || isHpSelfReporting) && selectedCompanyId && companyIsNotLargeBusiness && (
                <Controller
                  render={({ field }) => (
                    <Grid item sx={gridLabelStyles}>
                      <InputLabel sx={infoLabel}>Participation Percentage</InputLabel>
                      <Grid item xs={3}>
                        <NumberFormat
                          decimalScale={2}
                          allowLeadingZeros={false}
                          isAllowed={(values: NumberFormatValues) => handleIsAllowed(values)}
                          isNumericString={true}
                          margin="dense"
                          variant="outlined"
                          fullWidth={true}
                          color="primary"
                          customInput={TextField}
                          error={percentageHasError()}
                          helperText={
                            percentageHasError() ? (
                              <Typography sx={errorMessageStyle}>
                                Value must be a number in the range of 0 to 100
                              </Typography>
                            ) : (
                              ''
                            )
                          }
                          defaultValue={participationPercentage}
                          value={selectedParticipationPercentage}
                          onChange={(event) => {
                            const percentage = parseFloat(event.target.value);
                            const validPercentage = isNaN(percentage) ? 0 : percentage;
                            field.onChange(validPercentage);
                            setValue('participationPercentage', validPercentage);
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          }}
                        />
                      </Grid>
                      <Grid item sx={gridLabelStyles}>
                        <InputLabel sx={infoLabel}>Participation Total</InputLabel>
                        {!percentageHasError() && (
                          <NumberFormat
                            value={(valueTotal as number) * (selectedParticipationPercentage / 100)}
                            displayType={'text'}
                            prefix={'$'}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowLeadingZeros={false}
                            isNumericString={true}
                            thousandSeparator={true}
                            margin="dense"
                            variant="outlined"
                            color="primary"
                          />
                        )}
                      </Grid>
                    </Grid>
                  )}
                  name="participationPercentage"
                  control={control}
                  rules={{ min: 0, max: 100 }}
                />
              )}
              <Grid item>
                <FormControlLabel
                  sx={checkboxLabel}
                  control={
                    <Controller
                      name={'isSelfWork'}
                      control={control}
                      defaultValue={false}
                      render={({ field }) => {
                        return (
                          <Checkbox
                            color="primary"
                            checked={field.value}
                            onChange={(e) => {
                              field.onChange(e.target.checked);
                            }}
                          />
                        );
                      }}
                    />
                  }
                  label={'Is this contract Hensel Phelps self performed work?'}
                  labelPlacement="start"
                />
              </Grid>
              <Grid item>
                <Controller
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <Grid item sx={gridLabelStyles}>
                      <InputLabel sx={infoLabel}>Contract Date Execution</InputLabel>
                      <StyledDatePicker
                        value={value ? convertUTCDateToLocalDate(value) : null}
                        handleDateChange={(newDate) => {
                          onChange(newDate);
                          setValue('effectiveDate', newDate as DateTime);
                        }}
                        shouldDisplayAsError={!!error}
                      />
                    </Grid>
                  )}
                  name="effectiveDate"
                  control={control}
                  rules={{
                    required: false,
                  }}
                />
              </Grid>
              {shouldShowHubzoneMessage && (
                <Grid item xs={12}>
                  <StyledNotice
                    type={NoticeTypeOption.Notice}
                    message={
                      <Typography>
                        This trade partner is listed as HUBZONE certified. Make sure the certification is current.
                      </Typography>
                    }
                  />
                </Grid>
              )}
              {(isNonFederalProject || isHpSelfReporting) &&
                companyAgenciesAcceptedByProjectWithoutDups?.length !== 0 &&
                companyAgenciesHaveSpecialNoticeOrDocumentation && (
                  <>
                    {companyAgenciesAcceptedByProjectWithoutDups?.map((companyAgency) => {
                      return (
                        <Grid item sx={noticeStyles}>
                          <StyledNotice
                            type={NoticeTypeOption.Notice}
                            message={
                              <Grid item xs={12}>
                                <>
                                  <Grid item>
                                    {companyAgency?.specialNotice && (
                                      <Grid item sx={noticeStyles}>
                                        <Typography sx={noticeLabel}>Agency Notice</Typography>
                                        <Typography>{companyAgency?.specialNotice}</Typography>
                                      </Grid>
                                    )}
                                  </Grid>
                                  <Grid item xs={12} sx={noticeStyles}>
                                    <Divider variant="middle" light={true} />
                                  </Grid>
                                  <Grid item sx={noticeStyles}>
                                    {companyAgency?.url && (
                                      <Typography>
                                        For agency compliance documentation, please go to:{' '}
                                        {
                                          <a
                                            href={companyAgency?.url}
                                            target="_blank"
                                            rel="noreferrer"
                                            title={companyAgency?.url}
                                          >
                                            Agency Documentation ({companyAgency?.name})
                                          </a>
                                        }
                                      </Typography>
                                    )}
                                  </Grid>
                                </>
                              </Grid>
                            }
                          />
                        </Grid>
                      );
                    })}
                  </>
                )}
            </form>
          </Grid>
        </Grid>
      }
      actions={
        <Grid container justifyContent="center">
          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item>
                <StyledButtonSecondary
                  disabled={upsertContractIsLoading || updateProjectIsLoading}
                  label={'cancel'}
                  onClick={handleClose}
                />
              </Grid>
              <Grid item>
                <StyledButtonPrimary
                  label={'submit'}
                  type="submit"
                  disabled={!isDirty || !isFormValid() || upsertContractIsLoading || updateProjectIsLoading}
                  onClick={handleSubmit(onSubmit)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      isOpen={isOpen}
      handleClose={handleClose}
    />
  );
};

EditContractDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  contractId: PropTypes.string,
  companyId: PropTypes.string,
  phaseCode: PropTypes.string,
  valueTotal: PropTypes.number,
  effectiveDate: PropTypes.any,
};

export default EditContractDialog;
